import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    resourceMaster:[],
    isError: false,
    isAdding: false,
    isUpdating: false,
    totalCount: 0,
    error: {}
}

export const resourceMasterReducer = (state = inititalState, action) => {
    switch (action.type){
        case actionTypes.RESOURCE_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.RESOURCE_MASTER_CREATE_START: 
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }
        
        case actionTypes.RESOURCE_MASTER_UPDATE_START:
            return  {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.RESOURCE_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                resourceMaster: [...action.payload.resourceMaster],
                totalCount: action.payload.count,
                isError: false,
                isAdding: false,
                isUpdating: false,
                error: {}
            }

        case actionTypes.RESOURCE_MASTER_CREATE_SUCCESS: 
            const resourceD = [action.payload, ...state.resourceMaster]
            return {
                ...state,
                resourceMaster: [...resourceD],
                isAdding: false,
                error: {},
            }

        case actionTypes.RESOURCE_MASTER_UPDATE_SUCCESS: 
            const resourceU = [...state.resourceMaster]
            const findIndexRe = resourceU.findIndex(d => d.id === action.payload.id) 
            resourceU[findIndexRe] = {...action.payload}
            return {
                ...state,
                resourceMaster: [...resourceU],
                isUpdating: false,
                error: {}
            }

        case actionTypes.RESOURCE_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                isAdding: false,
                resourceMaster:[],
                isUpdating: false,
                error: {...action.payload}
            }
        default: 
            return state
    }
}
