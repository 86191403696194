import * as actionTypes from '../actionTypes';

const inititalState = {
    isDataFetch: false,
    ProcessInterview:[],
    ProcessExtInterview:[],
    ProcessAllInterview:[],
    LeavingOragnization: [],
    ProcessJoining:[],
    ProcessNonjoining:[],
    totalCount: 0,
    isError: false,
    isAdding:false,
    isUpdating: false,
    error:{} 
}

export const processReducer = (state = inititalState, action) => {
    switch (action.type){
       
//PROCESS INTERVIEW
        case actionTypes.INTERVIEW_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false
            } 
         
        case actionTypes.INTERVIEW_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                ProcessInterview: [...action.payload.ProcessInterview],
                totalCount: action.payload.count,
                isError: false
            }
        
        case actionTypes.INTERVIEW_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                ProcessInterview: [],
                error: {...action.payload}
            }

            case actionTypes.INTERVIEW_DATA_POST_START:
                return {
                    ...state,
                    // isAdding: true,
                    isError: false,
                    error: {}
                }

        case actionTypes.INTERVIEW_DATA_POST_SUCCESS:
            const newint = [action.payload, ...state.ProcessInterview]
            console.log(newint)
            return{
                ...state,
                ProcessInterview:[...newint],
                isAdding: false
            }

        case actionTypes.INTERVIEW_DATA_PUT_SUCCESS:
            const updateint = [...state.ProcessInterview]
            const findIndint = updateint.findIndex(d => d.id === action.payload.id) 
            updateint[findIndint] = {...action.payload}
            return {
                ...state,
                ProcessInterview: [...updateint]
            }


        //PROCESS_EXT_INTERVIEW
        case actionTypes.EXT_INTERVIEW_TABLE_DATA_FETCH_START: 
        return {
            ...state,
            isDataFetch: true,
            isError: false
        } 

        case actionTypes.EXT_INTERVIEW_TABLE_DATA_FETCH_SUCCESS: 
        return {
            ...state,
            isDataFetch: false,
            ProcessExtInterview: [...action.payload.ProcessExtInterview],
            totalCount: action.payload.count,
            isError: false
        }

        case actionTypes.EXT_INTERVIEW_TABLE_DATA_FETCH_FAILED: 
        return {
            ...state,
            isError: true,
            isDataFetch: false,
        }

        case actionTypes.EXT_INTERVIEW_DATA_POST_SUCCESS:
        const newextint = [action.payload, ...state.ProcessExtInterview]
        console.log(newextint)
        return{
            ...state,
            ProcessExtInterview:[...newextint]
        }

        case actionTypes.EXT_INTERVIEW_DATA_PUT_SUCCESS:
        const updateextint = [...state.ProcessExtInterview]
        const findIndextint = updateextint.findIndex(d => d.id === action.payload.id) 
        updateextint[findIndextint] = {...action.payload}
        return {
            ...state,
            ProcessExtInterview: [...updateextint]
        }
         
        
 //PROCESS_ALL_INTERVIEW
        case actionTypes.ALL_INTERVIEW_TABLE_DATA_FETCH_START: 
        return {
            ...state,
            isDataFetch: true,
            isError: false
        } 

        case actionTypes.ALL_INTERVIEW_TABLE_DATA_FETCH_SUCCESS: 
        return {
            ...state,
            isDataFetch: false,
            ProcessAllInterview: [...action.payload],
            isError: false
        }

        case actionTypes.ALL_INTERVIEW_TABLE_DATA_FETCH_FAILED: 
        return {
            ...state,
            isError: true,
            isDataFetch: false,
        }
        //LEAVING ORGANIZATIONS
        case actionTypes.LEAVINGORG_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            } 
         
        case actionTypes.LEAVINGORG_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                LeavingOragnization: [...action.payload.LeavingOragnization],
                totalCount:action.payload.count,
                isError: false,
                isAdding: false,
                isUpdating: false,
                error: {}
            }
        
        case actionTypes.LEAVINGORG_TABLE_DATA_FETCH_FAILED: 
        console.log('action.payload.error :>> ', action.payload);
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                isAdding: false,
                isUpdating: false,
                LeavingOragnization: [],
                error: {...action.payload}
                // error: action.payload.error
            }

        //Process Joining start

        case actionTypes.JOINING_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false.valueOf,
                error: {}
            } 
         
        case actionTypes.JOINING_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                ProcessJoining: [...action.payload.ProcessJoining],
                totalCount:action.payload.count,
                isError: false,
                isAdding: false,
                isUpdating: false,
                error: {}
            }
        
        case actionTypes.JOINING_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                isAdding: false,
                isUpdating: false,
                ProcessJoining: [],
                error: {...action.payload}
            }

        //Process Joining end

    //Process Non Joining start
    case actionTypes.NON_JOINING_TABLE_DATA_FETCH_START: 
        return {
            ...state,
            isDataFetch: true,
            isError: false,
            error: {}
        } 
     
    case actionTypes.NON_JOINING_TABLE_DATA_FETCH_SUCCESS: 
        return {
            ...state,
            isDataFetch: false,
            ProcessNonjoining: [...action.payload.ProcessNonjoining],
            totalCount:action.payload.count,
            isError: false,
            isAdding: false,
            isUpdating: false,
            error: {}
        }
    
    case actionTypes.NON_JOINING_TABLE_DATA_FETCH_FAILED: 
        return {
            ...state,
            isError: true,
            isDataFetch: false,
            isAdding: false,
            isUpdating: false,
            ProcessNonjoining: [],
            error: {...action.payload}
        }
    ///Process Non Joining End
        default: 
            return state
    }
}




