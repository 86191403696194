import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    requestClient: [],
    isUpdating: false,
    isError: false,
    count:0,
    error: {}
}

export const requestClientReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.REQUEST_CLIENT_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }
        case actionTypes.REQUEST_CLIENT_TABLE_DATA_UPDATE_START:
            return {
                ...state,
                isUpdating: true,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.REQUEST_CLIENT_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                requestClient: [...action.payload],
                count:action.count,
                isError: false,
                isUpdating: false,
                error: {}
            }

        case actionTypes.REQUEST_CLIENT_TABLE_DATA_UPDATE_SUCCESS:
            const updateRequestClient = [...state.requestClient]
            const findeIndexrequestClient = updateRequestClient.findIndex(d => d.id === action.payload.id)
            updateRequestClient[findeIndexrequestClient] = { ...action.payload }
            return {
                ...state,
                requestClient: [...updateRequestClient],
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }
        case actionTypes.REQUEST_CLIENT_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                requestClient: [],
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
