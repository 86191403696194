import { Pagination } from "react-bootstrap";
import { privateRequest } from "../axiosConfig/privateRequest";

export const fetchProcessInterview = ({
  searchValue,
  from,
  to,
  head,
  order,
  startDate,
  endDate,
}) => {
  console.log("startDate :>> ", startDate, endDate);
  if (startDate != undefined && endDate != undefined) {
    return privateRequest.get(
      `/interview?search=${searchValue}&from=${from}&to=${to}&startDate=${startDate}&endDate=${endDate}`
    );
  } else if (head != "" && order != "") {
    return privateRequest.get(
      `/interview?search=${searchValue}&from=${from}&to=${to}&sort=${head}&orderby=${order}`
    );
  } else {
    return privateRequest.get(
      `/interview?search=${searchValue}&from=${from}&to=${to}`
    );
  }
};

export const fetchLeaningOrg = ({ searchValue, from, to, head, order }) => {
  if (order == "" && head == "") {
    // return privateRequest
    // .get(`/resource/getAllArchive?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`)
    // // .then(res => res.data)
    return privateRequest.get(
      `/leaving-organization?search=${searchValue}&from=${from}&to=${to}`
    );
  } else {
    return privateRequest.get(
      `/leaving-organization?search=${searchValue}&from=${from}&to=${to}&sort=${head}&orderby=${order}`
    );
  }
};

// Post (Create)

export const addProcessInterview = (data) => {
  return privateRequest.post("/interview", data).then((res) => res?.data?.data);
};

// Put (update)

export const editProcessInterview = (data) => {
  return privateRequest.put(`/interview/${data.id}`, data).then((res) => res);
};

//EXT_INT start
export const fetchProcessExtInterview = ({ searchValue = "", from, to }) => {
  return privateRequest.get(
    `/external-interviews?search=${searchValue}&from=${from}&to=${to}`
  );
};

// Post (Create)

export const addProcessExtInterview = (data) => {
  return privateRequest
    .post("/external-interviews", data)
    .then((res) => res?.data?.data);
};

// Put (update)

export const editProcessExtInterview = (data) => {
  return privateRequest
    .put(`/external-interviews/${data.id}`, data)
    .then((res) => res);
};
export const fetchProcessJoining = ({ searchValue, from, to, head, order }) => {
  if (order == "" && head == "") {
    return privateRequest.get(
      `/joining?search=${searchValue}&from=${from}&to=${to}`
    );
  } else {
    return privateRequest.get(
      `/joining?search=${searchValue}&from=${from}&to=${to}&sort=${head}&orderby=${order}`
    );
  }
};
export const createJoining = (data) => {
  return privateRequest.post("/joining", data).then((res) => res?.data?.data);
};
export const updateJoining = (data) => {
  return privateRequest.put(`/joining/${data.id}`, data).then((res) => res);
};

//ALL_INT start
export const fetchProcessAllInterview = () => {
  return privateRequest.get("/allinterviews");
};

// export const fetchLeavingResource = () => {
//   return privateRequest.get("/leaving-organization").then((res) => res.data);
// };

export const fetchProcessNonJoining = ({
  searchValue,
  from,
  to,
  head,
  order,
  startDate,
  endDate,
}) => {
  if (order != "" && head != "") {
    return privateRequest.get(
      `/nonjoining?search=${searchValue}&from=${from}&to=${to}&sort=${head}&orderby=${order}`
    );
  } else if (startDate != undefined && endDate != undefined) {
    return privateRequest.get(
      `/nonjoining?search=${searchValue}&from=${from}&to=${to}&startDate=${startDate}&endDate=${endDate}`
    );
  } else {
    return privateRequest.get(
      `/nonjoining?search=${searchValue}&from=${from}&to=${to}`
    );
  }
  // console.log("apissearchVlaue",searchValue,from,to)
};
