import { privateRequest } from "../axiosConfig/privateRequest";

export const fetchExternalReport = () => {
  return privateRequest
    .get("/external-interview-report")
    .then((res) => res.data);
};

export const fetchInternalReport = (pagination) => {
  console.log('pagination', pagination)
  if(pagination.order == '' && pagination.head == ''){
     return privateRequest
     .get(`/interview-report?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}`)
     .then((res) => res.data);
  }else{ 
    return privateRequest.get(`/interview-report?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`).then((res) => res.data);
  }
 
};
