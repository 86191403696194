import { takeEvery, all, put, call } from "redux-saga/effects";

// actionTypes
import * as actionTypes from "../redux/actionTypes";

import {
  externalReportTableDataFetchSuccess,
  externalReportTableDataFetchFailed,
  internalReportTableDataFetchSuccess,
  internalReportTableDataFetchFailed,
} from "../redux/actions/reportActions";

import { fetchExternalReport } from "../api/services/reportService";
import { fetchInternalReport } from "../api/services/reportService";

// Internal Report
function* loadInternalReport(action) {
  try {
    console.log('action.payload :>> ', action.payload);
    const { data } = yield call(fetchInternalReport, action.payload);
    console.log(`data 11`, data);
    yield put(internalReportTableDataFetchSuccess({internalReport:data?.reports, count: data?.count}));
  } catch (error) {
    yield put(internalReportTableDataFetchFailed());
  }
}

function* watchInternalReport() {
  yield takeEvery(
    actionTypes.INTERNAL_INTERVIEW_REPORT_START,
    loadInternalReport
  );
}

//External Report

function* loadExternalReport() {
  try {
    const { data } = yield call(fetchExternalReport);
    console.log(`data 00`, data);
    yield put(externalReportTableDataFetchSuccess(data?.reports));
  } catch (error) {
    yield put(externalReportTableDataFetchFailed());
  }
}

function* watchExternalReport() {
  yield takeEvery(
    actionTypes.EXTERNAL_INTERVIEW_REPORT_START,
    loadExternalReport
  );
}

export function* reportSaga() {
  yield all([watchExternalReport(), watchInternalReport()]);
}
