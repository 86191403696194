import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    purchaseOrder: [],
    totalCount: 0,
    isError: false,
    isAdding: false,
    isUpdating: false,
    error: {}
}

export const purchaseOrderReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.PURCHASE_ORDER_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.PURCHASE_ORDER_TABLE_DATA_POST_START:
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }

        case actionTypes.PURCHASE_ORDER_TABLE_DATA_PUT_START:
            return {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.PURCHASE_ORDER_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                purchaseOrder: [...action.payload.purchase],
                totalCount: action.payload.count,
                isDataFetch: false,
                isError: false,
                error: {}
            }

        case actionTypes.PURCHASE_ORDER_TABLE_DATA_POST_SUCCESS:
            return {
                ...state,
                purchaseOrder: [...action.payload.purchase],
                totalCount: action.payload.count,
                isAdding: false,
                error: {},
            }

        case actionTypes.PURCHASE_ORDER_TABLE_DATA_PUT_SUCCESS:
            return {
                ...state,
                purchaseOrder: [...action.payload],
                isUpdating: false,
                error: {}
            }

        case actionTypes.PURCHASE_ORDER_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                purchaseOrder: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
