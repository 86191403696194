import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    externalProductMaster:[],
    totalCount: 0,
    isError: false,
    isAdding: false,
    isUpdating: false,
    error: {}
}

export const externalProductMasterReducer = (state = inititalState, action) => {
    switch (action.type){
        case actionTypes.EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.EXTERNAL_PRODUCT_MASTER_CREATE_START: 
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }
        
        case actionTypes.EXTERNAL_PRODUCT_MASTER_UPDATE_START:
            return  {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                externalProductMaster: [...action.payload.product],
                totalCount: action.payload.count,
                isError: false,
                error: {}
            }

        case actionTypes.EXTERNAL_PRODUCT_MASTER_CREATE_SUCCESS:
            return {
                ...state,
                externalProductMaster: [...action.payload.product],
                totalCount: action.payload.count,
                isAdding: false,
                error: {},
            }

        case actionTypes.EXTERNAL_PRODUCT_MASTER_UPDATE_SUCCESS:
            const externalProductMasterData = [...state.externalProductMaster];
            const findeIndexExP = externalProductMasterData.findIndex(d => d.id === action.payload.id);
            externalProductMasterData[findeIndexExP] = {...action.payload}
            return {
                ...state,
                externalProductMaster: [...externalProductMasterData],
                isUpdating: false,
                error: {}
            }

        case actionTypes.EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                externalProductMaster: [],
                isAdding: false,
                isUpdating: false,
                error: {...action.payload}
            }
        default: 
            return state
    }
}
