import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    archivedResource:[],
    isError: false,
    totalCount: 0,
    error: {}
}

export const archivedResourceReducer = (state = inititalState, action) => {
    switch (action.type){
        case actionTypes.ARCHIVED_RESOURCE_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
            }


        case actionTypes.ARCHIVED_RESOURCE_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                archivedResource: [...action.payload.archivedResource],
                totalCount: action.payload.count,
            }


        case actionTypes.ARCHIVED_RESOURCE_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isDataFetch:false,
                archivedResource: [],
                isError: true,
                error: {...action.payload}
            }
        default: 
            return state
    }
}
