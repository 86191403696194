import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    externalResourceMaster: [],
    isError: false,
    isAdding: false,
    isUpdating: false,
    error: {}
}

export const externalResourceMasterReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.EXTERNALRESOURCE_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.EXTERNALRESOURCE_CREATE_START:
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }

        case actionTypes.EXTERNALRESOURCE_UPDATE_START:
            return {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.EXTERNALRESOURCE_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                externalResourceMaster: [...action.payload],
                isError: false,
                isAdding: false,
                isUpdating: false,
                error: {}
            }

        case actionTypes.EXTERNALRESOURCE_CREATE_SUCCESS:
            const externalRM = [action.payload, ...state.externalResourceMaster]
            return {
                ...state,
                externalResourceMaster: [...externalRM],
                isAdding: false,
                error: {},
            }

        case actionTypes.EXTERNALRESOURCE_UPDATE_SUCCESS:
            const externalRMData = [...state.externalResourceMaster];
            const findIndexExRM = externalRMData.findIndex(t => t.id === action.payload.id)
            externalRMData[findIndexExRM] = { ...action.payload }
            return {
                ...state,
                externalResourceMaster: [...externalRMData],
                isUpdating: false,
                error: {}
            }

        case actionTypes.EXTERNALRESOURCE_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                externalResourceMaster: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
