import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    vendorMaster:[],
    isError: false,
    isAdding: false,
    totalCount: 0,
    isUpdating: false,
    error: {}
}

export const vendorMasterReducer = (state = inititalState, action) => {
    switch (action.type){
        case actionTypes.VENDOR_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.VENDOR_CREATE_START: 
        console.log('action.payload :>> ', action.payload);
        console.log('...state :>> ', state);
            return {
                
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }
        
        case actionTypes.VENDOR_UPDATE_START:
            return  {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.VENDOR_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                vendorMaster: [...action.payload.vendors],
                totalCount: action.payload.count,
                isError: false,
                error: {}
            }

        case actionTypes.VENDOR_CREATE_SUCCESS: 
            return {
                ...state,
                vendorMaster: [...action.payload.vendors],
                totalCount: action.payload.count,
                isAdding: false,
                error: {},
            }

        case actionTypes.VENDOR_UPDATE_SUCCESS: 
            const updateVendor = [...state.vendorMaster]
            const findIndexV = state.vendorMaster.findIndex(d => d.id === action.payload.id) 
            updateVendor[findIndexV] = action.payload
            return {
                ...state,
                vendorMaster: [...updateVendor],
                isUpdating: false,
                error: {}
            }

        case actionTypes.VENDOR_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                isAdding: false,
                vendorMaster: [],
                isUpdating: false,
                error: {...action.payload}
            }
        case actionTypes.VENDOR_CREATE_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                isAdding: false,
                vendorMaster: [],
                isUpdating: false,
                error: {...action.payload}
            }   
        case actionTypes.VENDOR_UPDATE_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                isAdding: false,
                vendorMaster: [],
                isUpdating: false,
                error: {...action.payload}
            }
        default: 
            return state
    }
}
