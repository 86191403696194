import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    clientAgreement: [],
    totalCount: 0,
    isError: false,
    isAdding: false,
    isUpdating: false,
    error: {}
}

export const clientAgreementReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.CLIENT_AGREEMENT_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.CLIENT_AGREEMENT_CREATE_START:
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }

        case actionTypes.CLIENT_AGREEMENT_UPDATE_START:
            return {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.CLIENT_AGREEMENT_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                clientAgreement: [...action.payload.clientAgreement],
                totalCount: action.payload.count,
                isError: false,
                error: {}
            }

        case actionTypes.CLIENT_AGREEMENT_CREATE_SUCCESS:
            return {
                ...state,
                clientAgreement: [...action.payload.clientAgreement],
                totalCount: action.payload.count,
                isAdding: false,
                error: {},
            }

        case actionTypes.CLIENT_AGREEMENT_UPDATE_SUCCESS:
            console.log('clientAgreement', action.payload.clientAgreement)
            return {
                ...state,
                clientAgreement: [...action.payload.clientAgreement],
                isUpdating: false,
                error: {}
            }

        case actionTypes.CLIENT_AGREEMENT_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                clientAgreement: [],

                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
