import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    projectTarget:[],
    crProjectTarget:[],
    isError: false,
    isAdding: false,
    isUpdating: false,
    totalCount: 0,
    error: {}
}

export const projectTargetReducer = (state = inititalState, action) => {
    switch (action.type){
        case actionTypes.PROJECT_TABLE_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.CR_PROJECT_TARGET_DATA_FETCH_START: 
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.PROJECT_TARGET_CREATE_START: 
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }
        
        case actionTypes.PROJECT_TARGET_UPDATE_START:
            return  {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.PROJECT_TABLE_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                projectTarget: [...action.payload.projectTarget],
                totalCount: action.payload.count,
                // isAdding: false,
                isError: false,
                error: {}
            }

        case actionTypes.CR_PROJECT_TARGET_DATA_FETCH_SUCCESS: 
            return {
                ...state,
                isDataFetch: false,
                crProjectTarget: [...action.payload.crProjectTarget],
                totalCount: action.payload.count,
                isError: false,
                error: {}
            }

        case actionTypes.PROJECT_TARGET_CREATE_SUCCESS: 
            return {
                ...state,
                projectTarget: [...action.payload.projectTarget],
                isAdding: false,
                error: {},
            }

        case actionTypes.PROJECT_TARGET_UPDATE_SUCCESS: 
            const updateProject = [...state.projectTarget]
            const findeIndexProjectTarget = state.projectTarget.findIndex(d => d.id === action.payload.id) 
            updateProject[findeIndexProjectTarget] = action.payload
            return {
                ...state,
                projectTarget: [...updateProject],
                isUpdating: false,
                error: {}
            }

        case actionTypes.PROJECT_TABLE_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                isAdding: false,
                projectTarget: [],
                isUpdating: false,
                error: {...action.payload}
            }
            
        case actionTypes.CR_PROJECT_TARGET_DATA_FETCH_FAILED: 
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                // isAdding: false,
                crProjectTarget: [],
                isUpdating: false,
                error: {...action.payload}
            }

        default: 
            return state
    }
}
